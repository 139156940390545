import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import CheatsheetsListLimit from '../components/CheatsheetsListLimit'
import PicturesListLimit from '../components/PicturesListLimit'
import Content, { HTMLContent } from '../components/Content'

export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div className='main uk-width-auto section' id='main'>
      <div className="single_post hfeed" id="single_post">
        <article className="post hentry">

          <div className="post_content">
            <h1 className="post_title entry-title uk-article-title">{title}</h1>
            <div className="post_body entry-content uk-margin-large-bottom" id="post_body_6501734076252104728"
                 data-uk-lightbox="toggle:a[imageanchor]">
              <PageContent className="content" content={content} />
            </div>
          </div>

        </article>
      </div>
      <div className="single_post hfeed">
        <article className="post hentry">

          <div className="post_content">
            <h2 className="post_title entry-title uk-article-title">Nejnovější taháky</h2>
          </div>

        </article>
      </div>
      <div className='widget Blog' id='Blog1'>
        <div className='blog_posts hfeed uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@l'
             data-uk-grid='masonry:true' id='blog_posts'>
          <CheatsheetsListLimit />
        </div>
        <div className='blog_pager uk-text-center uk-margin-large-top'>
          <Link className='blog-pager-older-link uk-button uk-button-default'
             to='/tahaky' title='Prohlédnout další taháky'>
            <span className='uk-margin-small-right'>Prohlédnout další</span>
          </Link>
        </div>
      </div>
      <div className="single_post hfeed">
        <article className="post hentry">

          <div className="post_content">
            <h2 className="post_title entry-title uk-article-title">Nejnovější obrázky</h2>
          </div>

        </article>
      </div>
      <div className='widget Blog' id='Blog2'>
        <div className='blog_posts hfeed uk-child-width-1-1 uk-child-width-1-2@m uk-child-width-1-3@l'
             data-uk-grid='masonry:true' id='blog_posts'>
          <PicturesListLimit />
        </div>
        <div className='blog_pager uk-text-center uk-margin-large-top' id='blog_pager'>
          <Link className='blog-pager-older-link uk-button uk-button-default'
                to='/obrazky' title='Prohlédnout další obrázky'>
            <span className='uk-margin-small-right'>Prohlédnout další</span>
          </Link>
        </div>
      </div>
    </div>
)}

/*
<section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <PageContent className="content" content={content} />
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Poslední taháky
                  </h3>
                  <CheatsheetsListLimit />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/tahaky">
                      Prohlédnout další
                    </Link>
                  </div>
                </div>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Poslední obrázky
                  </h3>
                  <PicturesListLimit />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/obrazky">
                      Prohlédnout další
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
 */

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        content={html}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subheading
      }
    }
  }
`

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import CheatsheetsList from './CheatsheetsList'

export default () => (
  <StaticQuery
    query={graphql`
      query CheatsheetsListLimitQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "cheatsheet-post" } } }
          limit: 3
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fixed(width: 343, quality: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                tags
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CheatsheetsList data={data} count={count} />}
  />
)
